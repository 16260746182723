<template>
    <div>
        <el-dialog
            title="创建巡检"
            :modal='needModal'
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <edit-inspection-form ref="inspectionEdit" @submit="handleSubmit"></edit-inspection-form>
            </div>
            <div slot="footer">
                <el-button class="plainBtn" @click="cancel">取消同步发布巡检</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import editInspectionForm from '../../inspection/child/inspection-edit-form.vue'
    export default {
        components: {
            editInspectionForm
        },
        data() {
            return {
                dialogVisible: true,
                showDialog: false,
                needModal: false
            };
        },
        created () {
            
        },
        mounted () {
            setTimeout(()=>{
                this.needModal = true
            },500)
        },
        methods:{
            submit () {
                this.$refs['inspectionEdit'].submit()
            },
            cancel () {
                this.$emit('cancel')
                this.dialogVisible = false
            },
            handleSubmit (datas) {
                this.$emit('submit', {
                    ...datas
                })
                this.dialogVisible = false
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
        padding-right: 10px;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>