<template>
    <div>
        <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="left">
            <el-form-item label="巡检站点" prop="stationIdList">
                <le-select-remote-search style="padding: 0;"
                    large
                    isObj
                    multiple
                    selectAll
                    collapse
                    :multipleLimit='50'
                    v-model="form.stationIdList" 
                    :options="optionsStation" 
                    :defaultOptions='disableOptionsStation'
                    placeholder="请选择站点（可输入搜索）" />
            </el-form-item>
            <el-form-item label="批量填写" prop="stationIds">
                <el-input
                    style="width: 400px;"
                    type="textarea"
                    @blur="getStationInfoByIds"
                    :autosize="{ minRows: 4 }"
                    placeholder="请输入站点ID；以“,”隔开,至多50个站点；只能输入运营中站点ID。"
                    maxlength="300"
                    show-word-limit
                    v-model="stationIds">
                </el-input>
            </el-form-item>
            <div class="a-flex-rfsc" v-if="errorStationList.length">
                <span class="a-c-error a-fw-700">异常站点：</span>
                <el-button type="danger" size='mini' @click="handleRemoveErrorStation">删除异常站点</el-button>
            </div>
            <div class="a-flex-cfsfs">
                <span v-for="(item, index) in errorStationList" :key="index" class="a-c-error">{{ index + 1 }}. {{ item.stationName }}</span>
            </div>
            <el-form-item label="执行人" prop="solveRbacUser">
                <le-select-remote-search style="padding: 0;"
                    large
                    v-model="form.solveRbacUser" 
                    :options="optionsSolveRbacUser" 
                    :defaultOptions='disableOptionsRbacUser'
                    placeholder="请选择站点（可输入搜索）" />
            </el-form-item>
            <el-form-item label="截止时间" prop="endTime">
                <el-date-picker
                    v-model="form.endTime"
                    type="datetime"
                    :defaultTime="'23:59:59'"
                    valueFormat="yyyy-MM-dd HH:mm:ss" 
                    :pickerOptions='pickerOptions'
                    placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="巡检模板" prop="patrolTemplateId">
                <le-select-remote-search style="padding: 0;"
                    large
                    v-model="form.patrolTemplateId" 
                    :options="optionsPatrolTemplate" 
                    placeholder="请选择站点（可输入搜索）" />
            </el-form-item>
            <el-form-item label="重要程度" prop="importanceLevel">
                <le-select-local-search large style="padding: 0;" v-model="form.importanceLevel" :options="importanceLevelDic" />
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input
                    style="width: 400px;"
                    type="textarea"
                    :autosize="{ minRows: 4 }"
                    placeholder="请输入内容"
                    maxlength="200"
                    show-word-limit
                    v-model="form.remark">
                </el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    stationId: '',
                    stationIdList: [],
                    solveRbacUser: '',
                    endTime: '',
                    patrolTemplateId: '',
                    importanceLevel: '',
                    remark: '',
                },
                stationIds: '',
                errorStationList: [],//
                rules: {
                    stationId: [{required: true, message:'请选择巡检点', trigger: 'change'}],
                    stationIdList: [{required: true, message:'请选择巡检点', trigger: 'change'}],
                    solveRbacUser: [{required: true, message:'请选择执行人', trigger: 'change'}],
                    endTime: [{required: true, message:'请选择截止时间', trigger: 'change'}],
                    patrolTemplateId: [{required: true, message:'请选择巡检模板', trigger: 'change'}],
                    importanceLevel: [{required: true, message:'请选择重要程度', trigger: 'change'}],
                },
                optionsStation: {
                    url: this.$Config.apiUrl.getStationList,
                    method: "post",
                    params: {
                        status: 2
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "stationId",
                    searchKey: "nameOrId",  //incomeDistriId
                    pageSize: 200
                },//站点数据
                disableOptionsStation: [],
                optionsSolveRbacUser: {
                    url: this.$Config.apiUrl.getExecutor,
                    method: "post",
                    params: {
                    },
                    showLoading: false,
                    labelKey: "userName",
                    otherLabelKey: "companyName",
                    valueKey: "id",
                    searchKey: "name", 
                    pageSize: 200
                },
                disableOptionsRbacUser: [],
                optionsPatrolTemplate: {
                    url: this.$Config.apiUrl.inspectionTemplateList,
                    method: "post",
                    params: {
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name", 
                    pageSize: 200
                },
                pickerOptions: {
                    disabledDate: (time) => {
                        return time.getTime() < Date.now() - 8.64e7 || time.getTime() > Date.now() + 365 * 24 * 3600 * 1000  
                    },
                },
                importanceLevelDic: [],
                stationInfo: '',
            };
        },
        mounted () {
            this.$getDic('importLevel','select').then(res=>{ this.importanceLevelDic = res; })
            this.$eventBus.$on('workInspection', (res)=>{
                if(res.solveRbacUser) {
                    this.form.solveRbacUser = res.solveRbacUser
                    this.disableOptionsRbacUser = [{
                        userName: res.solveRbacUserName,
                        id: res.solveRbacUser
                    }]
                }
                if(res.stationId) {
                    this.form.stationIdList = [{
                        name: res.stationName,
                        stationId: res.stationId
                    }]
                    this.disableOptionsStation = [{
                        name: res.stationName,
                        stationId: res.stationId
                    }]
                }
            })
        },
        beforeDestroy () {
            this.$eventBus.$off('workInspection')
        },
        watch: {
            'form.stationId' : {
                handler (val) {
                    console.log(val);
                    // this.getStationInfo()
                }
            },
        },
        methods:{
            getStationInfo () {
                // this.$Axios._get({
                //     url: this.$Config.apiUrl.getStationDetail,
                //     params: {
                //         stationId: this.form.stationId
                //     }
                // }).then(({
                //     data
                // }) => {
                //     this.stationInfo = data.station
                // })
            },
            handleRemoveErrorStation () {
                let errorStationList = this.errorStationList
                errorStationList.map(item=>{
                    let errorStationIndex = this.form.stationIdList.findIndex(ite=>{
                        return ite.value == item.stationId
                    })
                    this.form.stationIdList.splice(errorStationIndex, 1)
                })
                this.errorStationList = []
            },
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        this.errorStationList = await this.inspectionCheckStation()
                        if(this.errorStationList && this.errorStationList.length) {
                            this.$message.error('您所选择的站点在当前执行人名下有未完成的巡检单，请检查后重新提交。')
                            return
                        }
                        let stationIds = this.form.stationIdList.map(item=>{
                            return item.value
                        })
                        this.$emit('submit', {
                            ...this.form,
                            endTime: this.form.endTime,
                            stationIdList: stationIds
                        })
                    }
                })
            },
            inspectionCheckStation () {
                return new Promise((resolve, reject)=>{
					let stationIdList = this.form.stationIdList.map(item=>{
						return {
							stationId: item.value,
							stationName: item.label
						}
					})
					this.$Axios._post({
                        url: this.$Config.apiUrl.inspectionCheckStation,
                        method: "post",
                        params: {
                            stationIdList: stationIdList,
                            solveRbacUser: this.form.solveRbacUser
                        },
                    })
                    .then(({data})=>{
                        resolve(data)
                    })
                    .catch(err=>{
                        resolve(false)
                    })
				})
                
            },
            getStationInfoByIds () {
                if(this.stationIds) {
                    this.$Axios._post({
                        url: this.$Config.apiUrl.getStationList,
                        method: "post",
                        params: {
                            pageNum: 1,
							pageSize: 100,
							status: 2,
							stationIdList: this.stationIds.replaceAll('，').split(',')
                        },
                    })
                    .then(({data})=>{
                        if(data.list && data.list.length) {
                            data.list.map(item=>{
                                let stationIndex = this.form.stationIdList.findIndex(ite=>{
                                    return ite.value == item.stationId
                                })
                                if(stationIndex == -1 && this.form.stationIdList.length < 50) {
                                    this.form.stationIdList.push({
                                        label: item.name,
                                        value: item.stationId
                                    })
                                }
                            })
                            if(data.list.length + this.form.stationIdList.length > 50) {
                                this.$message.error('最多添加50个站点')
                            }
                        }else {
                            this.$message.error('未获取到运营中站点信息')
                        }
                        this.stationIds = ''
                    })
                    .catch(err=>{
                    })

				}
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
        padding-right: 10px;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>