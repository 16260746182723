<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="content">
            <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="left">
                <el-form-item label="工单标题" prop="title">
                    <el-input style="width: 400px" v-model="form.title" maxlength="20" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="工单类型" prop="errorType">
                    <le-select-remote-search style="padding: 0;width: 400px"
                        large
                        v-model="form.errorType" 
                        :options="optionsErrorType" 
                        placeholder="请选择工单类型" />
                </el-form-item>
                <el-form-item label="站点" prop="stationIdObj">
                    <le-select-remote-search style="padding: 0;width: 400px"
                        large
                        isObj
                        v-model="form.stationIdObj" 
                        :options="optionsStation" 
                        placeholder="请选择站点（可输入搜索）" />
                    <span v-if="stationInfo" class="a-plr-10">{{ stationInfo.address || '-' }}</span>
                </el-form-item>
                <el-form-item label="截止时间" prop="endTime">
                    <el-date-picker
                        v-model="form.endTime"
                        type="datetime"
                        valueFormat="yyyy-MM-dd HH:mm:ss" 
                        :defaultTime="'23:59:59'"
                        :pickerOptions="pickerOptions" 
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="执行人" prop="solveRbacUserObj">
                    <le-select-remote-search style="padding: 0;width: 400px"
                        large
                        isObj
                        v-model="form.solveRbacUserObj" 
                        :options="optionsSolveRbacUser" 
                        placeholder="请选择执行人（可输入搜索）" />
                </el-form-item>
                <el-form-item label="重要程度" prop="importanceLevel">
                    <le-select-local-search large style="padding: 0;width: 380px" v-model="form.importanceLevel" :options="importanceLevelDic" />
                </el-form-item>
                <el-form-item label="描述" prop="content">
                    <el-input
                        style="width: 400px;"
                        type="textarea"
                        :autosize="{ minRows: 4 }"
                        placeholder="请输入内容"
                        maxlength="200"
                        show-word-limit
                        v-model="form.content">
                    </el-input>
                </el-form-item>
                <el-form-item prop="pictureList" label="图片">
                    <le-upload-file-img-ly ref="pic" v-model="form.pictureList" :limit='20'></le-upload-file-img-ly>
                </el-form-item>
                <el-form-item prop="response_medias" label="">
                    <el-switch
                        @change='handleChangeInspection'
                        v-model="isSyncPublishInspection"
                        :inactive-value="0"
                        :active-value="1"
                        active-color="#409EFF"
                        inactive-color="#F56C6C">
                    </el-switch>
                    <span class="a-mlr-12" :class="isSyncPublishInspection?'a-c-blue':'a-c-999'">同步发布巡检任务</span>
                </el-form-item>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
        <edit-inspection ref="editInspection" v-show='showDialog' :solveRbacUser='form.solveRbacUser' :stationId="form.stationId" @submit='handleSubmitInspection' @cancel='handleCancelInspection'></edit-inspection>
    </div>
</template>

<script>
import LeUploadFileImgLy from '../components/form/le-upload-file-img-ly.vue';
    import editInspection from './child/edit-inspection.vue';
    export default {
        components: { editInspection, LeUploadFileImgLy },
        data() {
            var checkStation = (rule, value, callback) => {
                if(this.form.errorType != 8 && !this.form.stationIdObj){
                    callback(new Error('请选择站点'))
                }else{
                    callback()
                }
            };
            return {
                showDialog: false,
                form: {
                    title: '',
                    errorType: '',
                    stationId: '',
                    stationIdObj: '',
                    endTime: '',
                    solveRbacUser: '',
                    solveRbacUserObj: '',
                    importanceLevel: '',
                    content: '',
                    pictureList: [],
                },
                inspectionForm: {

                },
                isSyncPublishInspection: 0,
                rules: {
                    title: [{required: true, message:'请输入标题', trigger: 'blur'}],
                    errorType: [{required: true, message:'请选择工单类型', trigger: 'change'}],
                    // stationIdObj: [{required: true, message:'请选择站点', trigger: 'change'}],
                    stationIdObj: [{ validator: checkStation, trigger: 'change' }],
                    endTime: [{required: true, message:'请选择截止时间', trigger: 'change'}],
                    solveRbacUserObj: [{required: true, message:'请选择执行人', trigger: 'change'}],
                    importanceLevel: [{required: true, message:'请选择重要程度', trigger: 'change'}],
                },
                optionsStation: {
                    url: this.$Config.apiUrl.getStationList,
                    method: "post",
                    params: {
                        status: 2
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "stationId",
                    searchKey: "nameOrId",  //incomeDistriId
                    pageSize: 200
                },//站点数据
                optionsSolveRbacUser: {
                    url: this.$Config.apiUrl.getExecutor,
                    method: "post",
                    params: {
                    },
                    showLoading: false,
                    labelKey: "userName",
                    otherLabelKey: "companyName",
                    valueKey: "id",
                    searchKey: "name", 
                    pageSize: 200
                },
                optionsErrorType: {
                    url: this.$Config.apiUrl.getWorkOrderErrorType,
                    method: "post",
                    params: { },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "searchKey",  //incomeDistriId
                },
                pickerOptions: {
                    disabledDate: (time) => {
                        return time.getTime() < Date.now() - 8.64e7 || time.getTime() > Date.now() + 365 * 24 * 3600 * 1000  
                    },
                },
                importanceLevelDic: [],//重要程度字典
                stationInfo: '',
                hasInspection: '',//
            };
        },
        created () {
            // this.groupId = this.$route.query.groupId
            // if(this.groupId) this.getWarningGroupDetails()
            this.$getDic('importLevel','select').then(res=>{ this.importanceLevelDic = res; })
            setTimeout(()=>{
                this.showDialog = true
            },1000)
        },
        computed: {
            
        },
        mounted () {
            this.$refs['editInspection'].dialogVisible = false
        },
        watch: {
            'form.stationIdObj' : {
                deep: true,
                handler (val) {
                    this.stationInfo = ''
                    this.$eventBus.$emit('workInspection', {
                        stationId: this.form.stationIdObj ? this.form.stationIdObj.value : '',
                        stationName: this.form.stationIdObj ? this.form.stationIdObj.label : ''
                    })
                    if(val) {
                        this.getStationInfo()
                        this.getInspectionHasPatrolt()
                    }
                }
            },
            'form.solveRbacUserObj': {
                deep: true,
                handler (val) {
                    this.$eventBus.$emit('workInspection', {
                        solveRbacUserName: this.form.solveRbacUserObj.label,
                        solveRbacUser: this.form.solveRbacUserObj.value,
                    })
                }
            }
        },
        methods: {
            getInspectionHasPatrolt () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.inspectionHasPatrolt,
                    params: {
                        stationId: this.form.stationIdObj.value,
                        day: 7
                    },
                })
                .then(({data})=>{
                    if (data > 0) {
                        this.isSyncPublishInspection = 0
                        this.hasInspection = true //七日内有巡检
                    } else {
                        this.isSyncPublishInspection = 1
                        this.hasInspection = false //七日内无巡检
                    }
                })
                .catch(err=>{

                })
            },
            handleCancelInspection () {
                this.isSyncPublishInspection = 0
            },
            handleChangeInspection () {
                // if(this.isSyncPublishInspection) {
                //     this.$refs['editInspection'].dialogVisible = true
                //     this.$nextTick(()=>{
                //         this.$eventBus.$emit('workInspection', {
                //             stationId: this.form.stationIdObj.value,
                //             stationName: this.form.stationIdObj.label,
                //             solveRbacUserName: this.form.solveRbacUserObj.label,
                //             solveRbacUser: this.form.solveRbacUserObj.value,
                //         })
                //     })
                // }
            },
            handleSubmitInspection (datas) {
                this.inspectionForm = {
                    ...datas
                }
                this.submit()
            },
            getStationInfo () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getStationDetail,
                    params: {
                        stationId: this.form.stationIdObj.value
                    }
                }).then(({
                    data
                }) => {
                    this.stationInfo = data.station
                })
            },
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        if(this.isSyncPublishInspection && this.isNullObj(this.inspectionForm)) {
                            if(!this.hasInspection) {
                                this.$confirm('当前站点7日内无巡检，是否同步发布巡检任务？', '温馨提示', {
                                    confirmButtonText: '创建巡检',
                                    cancelButtonText: '仅发布工单',
                                    type: 'warning'
                                }).then(_=>{
                                    this.$refs['editInspection'].dialogVisible = true
                                    this.$nextTick(()=>{
                                        this.$eventBus.$emit('workInspection', {
                                            stationId: this.form.stationIdObj.value,
                                            stationName: this.form.stationIdObj.label,
                                            solveRbacUserName: this.form.solveRbacUserObj.label,
                                            solveRbacUser: this.form.solveRbacUserObj.value,
                                        })
                                    })
                                }).catch(_=>{
                                    // 取消同步发布，直接创建工单
                                    this.isSyncPublishInspection = 0
                                    let reqData
                                    reqData = {
                                        ...this.form,
                                        stationId: this.form.stationIdObj.value,
                                        solveRbacUser: this.form.solveRbacUserObj.value,
                                        endTime: this.form.endTime,
                                        pictureList: JSON.stringify(this.form.pictureList)
                                    }
                                    this.$Axios._post({
                                        url: this.$Config.apiUrl.addWorkOrder,
                                        method: "post",
                                        params: reqData,
                                    })
                                    .then(res=>{
                                        if (res.result.code == 0) {
                                            this.$message.success('工单创建成功')
                                            this.$router.back()
                                            this.$store.dispatch("delView", this.$route);
                                        } else {
                                            this.$message.error(res.result.message)
                                        }
                                    })
                                    .catch(err=>{

                                    })
                                })
                            }else {
                                this.$refs['editInspection'].dialogVisible = true
                                this.$nextTick(()=>{
                                    this.$eventBus.$emit('workInspection', {
                                        stationId: this.form.stationIdObj.value,
                                        stationName: this.form.stationIdObj.label,
                                        solveRbacUserName: this.form.solveRbacUserObj.label,
                                        solveRbacUser: this.form.solveRbacUserObj.value,
                                    })
                                })
                            }
                            return
                        }
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(async _=>{
                            let createInspectionSuccess
                            if(this.isSyncPublishInspection && !this.isNullObj(this.inspectionForm)) {
                                createInspectionSuccess = await this.createInspection() // 同步创建巡检
                            }
                            let reqData
                            reqData = {
                                ...this.form,
                                stationId: this.form.stationIdObj.value,
                                solveRbacUser: this.form.solveRbacUserObj.value,
                                endTime: this.form.endTime,
                                pictureList: JSON.stringify(this.form.pictureList)
                            }
                            this.$Axios._post({
                                url: this.$Config.apiUrl.addWorkOrder,
                                method: "post",
                                params: reqData,
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('工单创建成功')
                                    this.$router.back()
                                    this.$store.dispatch("delView", this.$route);
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(err=>{ console.log(err); })
                    }
                })
                
            },
            createInspection () {
                return new Promise((resolve, reject) => {
                    this.$Axios._post({
                        url: this.$Config.apiUrl.inspectionEdit,
                        method: "post",
                        params: {
                            ...this.inspectionForm,
                            // patrolType: 2,//同步发布的巡检，发布类型为   2--工单
                        },
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('同步创建巡检成功')
                            resolve(true)
                        } else {
                            this.$message.error(res.result.message)
                            reject(false)
                        }
                    })
                    .catch(err=>{
                        reject(false)
                    })
                })
            },
            cancelSubmit () {
                this.$confirm('是否确认返回？','温馨提示')
                .then(_=>{
                    this.$router.back()
                    this.$store.dispatch("delView", this.$route);
                })
                .catch(_=>{})
            },
            isNullObj (object) {
                let isEmpty = true
                if(Object.prototype.toString.call(object) === '[object Object]'){
                    Object.keys(object).forEach(el => {
                        if (object[el] !== null && object[el] !== '') {
                            isEmpty = false
                        }
                    })
                }else{
                    isEmpty = false
                }
                
                return isEmpty
            },
        },
    }
</script>

<style lang="scss" scoped>
    .content{
        font-size: 14px;
        height: 100%;
        overflow-y: auto;
    }
</style>